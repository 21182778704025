<section id="bookings" class="section gray-bg">
  <div class="container">
      <div class="row sm-m-25px-b m-35px-b">
          <div class="col-md-12">
              <div class="section-title">
                  <h3 class="dark-color text-uppercase">Get in touch</h3>
              </div>
          </div>
      </div>
      <!-- form -->
      <div class="row justify-content-center">
          <div class="col-lg-8 m-15px-tb">
              <div class="contact-form box-shadow">
                  <h4 class="dark-color font-alt m-20px-b">Bookings</h4>
                  <form id="contact-form" #contactForm="ngForm" (ngSubmit)="onSubmit(contactForm.value)">
                      <div class="row">
                          <div class="col-md-6">
                              <div class="form-group">
                                  <input name="Name" id="name" placeholder="Name *" required class="form-control" type="text" ngModel>
                                  <span class="input-focus-effect theme-bg"></span>
                              </div>
                          </div>
                          <div class="col-md-6">
                              <div class="form-group">
                                  <input name="Email" id="email" placeholder="Email *" required class="form-control" type="email" ngModel>
                                  <span class="input-focus-effect theme-bg"></span>
                              </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group">
                              <label class="form-control">Booking Date</label>
                                <span class="input-focus-effect theme-bg"></span>
                            </div>
                        </div>


                          <div class="col-md-6">
                            <div class="form-group">
                                <input name="date" id="date" placeholder="Date *" required class="form-control" type="date" ngModel>
                                <span class="input-focus-effect theme-bg"></span>
                            </div>
                        </div>

                          <div class="col-12">
                              <div class="form-group">
                                  <input name="Subject" id="subject" placeholder="Subject *" required class="form-control" type="text" ngModel>
                                  <span class="input-focus-effect theme-bg"></span>
                              </div>
                          </div>
                          <div class="col-md-12">
                              <div class="form-group">
                                  <textarea name="message" id="message" placeholder="Your message *" required rows="3" class="form-control" ngModel></textarea>
                                  <span class="input-focus-effect theme-bg"></span>
                              </div>
                          </div>
                          <div class="col-md-12">
                              <div class="send">
                                  <button class="m-btn m-btn-theme" type="submit" value="Send">Send</button>
                              </div>
                              <span id="suce_message" class="text-success" style="display: none">Message Sent Successfully</span>
                              <span id="err_message" class="text-danger" style="display: none">Message Sending Failed</span>
                          </div>
                      </div>
                  </form>
              </div>
          </div> <!-- col -->
          <div class="col-lg-4 m-15px-tb">
              <div class="contact-info media box-shadow">
                  <div class="icon">
                      <i class="ti-location-pin"></i>
                  </div>
                  <div class="media-body">
                      <h6 class="dark-color font-alt">Our Address</h6>
                      <p>978 Fredenharry Road, Strubensvalley, Roodepoort, 1724</p>
                  </div>
              </div>
              <div class="contact-info media box-shadow">
                  <div class="icon">
                      <i class="ti-mobile"></i>
                  </div>
                  <div class="media-body">
                      <h6 class="dark-color font-alt">Our Phone</h6>
                      <p>
                        Mobile: 078 103 2003<br>
                        Mobile: 071 285 4093<br>
                      </p>
                  </div>
              </div>
              <div class="contact-info media box-shadow">
                  <div class="icon">
                      <i class="ti-email"></i>
                  </div>
                  <div class="media-body">
                      <h6 class="dark-color font-alt">Our Email</h6>
                      <p>
                        info@kokeletsomoiloa.co.za
                      </p>
                  </div>
              </div>
          </div>
      </div>
      <!-- end form -->
  </div>
</section>
