import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { RestApiService } from '../shared/rest-api.service';
import { EmailMessage } from '../shared/email-message';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.css']
})
export class BookingsComponent implements OnInit {

  constructor(public restApi: RestApiService) { }
  public emailMessage: EmailMessage;
  formdata;
  email;
  ngOnInit(): void {this.formdata = new FormGroup({
    email: new FormControl('', Validators.compose([
       Validators.required,
       Validators.pattern('[^ @]*@[^ @]*')
    ])),
    name: new FormControl('', Validators.compose([
      Validators.required
     ])),
    message: new FormControl('', Validators.compose([
      Validators.required
     ]))
  });
  }

  onSubmit(data): void {
      console.log('Form Submitted!');

      this.emailMessage = new EmailMessage();
      this.emailMessage.ToEmail = 'bookings@coachkokeletsomoiloa.co.za';
      this.emailMessage.FromEmail = data.Email;
      this.emailMessage.Subject = data.Subject;
      this.emailMessage.Message = 'Hi, \n\r' + data.Name + ' would like to request a booking on the date : ' + data.date + '\n\rTheir message is : \n\r' + data.message;
      this.emailMessage.Name = data.Name;

      this.restApi.sendEmail(this.emailMessage).subscribe((d: {}) => {

      });
      this.formdata.reset();
  }
}

