
<app-header></app-header>
    <!-- <router-outlet></router-outlet> -->
    <div id="home">
      <app-home></app-home>
    </div>
    <app-about></app-about>
    <!-- <app-contact></app-contact> -->
    <app-services></app-services>
    <app-bookings></app-bookings>
<app-footer></app-footer>
