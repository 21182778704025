<!-- This nav gives you links to click, which tells the router which route to use (defined in the routes constant in  AppRoutingModule) -->
<header>
  <nav class="navbar header-nav header-dark navbar-expand-lg">
      <div class="container">
          <!-- Brand -->
          <a class="navbar-brand" fragment="home" routerLink="/home">Kokeletso Moiloa<span class="theme-bg"></span></a>
          <!-- / -->
          <!-- Mobile Toggle -->
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse-toggle" aria-controls="navbar-collapse-toggle" aria-expanded="false" aria-label="Toggle navigation">
              <span></span>
              <span></span>
              <span></span>
          </button>
          <!-- / -->
          <!-- Top Menu -->
          <div class="collapse navbar-collapse justify-content-end" id="navbar-collapse-toggle">
              <ul class="navbar-nav ml-auto">
                <li><a class="nav-link" fragment="home" routerLink="/home" routerLinkActive="active">Home</a></li>
                <li><a class="nav-link" fragment="about" routerLink="/about" routerLinkActive="active">About</a></li>
                <li><a class="nav-link" fragment="services" routerLink="/services" routerLinkActive="active">Services</a></li>
                <li><a class="nav-link" fragment="bookings" routerLink="/bookings" routerLinkActive="active">Bookings</a></li>
              </ul>
          </div>
          <!-- / -->
      </div><!-- Container -->
  </nav> <!-- Navbar -->
</header>
