<section id="services" class="section gray-bg">
  <div class="container">
      <div class="row sm-m-25px-b m-35px-b">
          <div class="col-md-12">
              <div class="section-title">
                  <h3 class="dark-color text-uppercase">My Services</h3>
                  <p class="text-uppercase small">Certified professional Counsellor and Mental Health Coach</p>
              </div>
          </div>
      </div>
      <div class="row">
          <!-- feaure box -->
          <div class="col-sm-6 col-lg-4 m-15px-tb">
              <div class="feature-box-1 box-shadow-lg">
                  <div class="icon">
                      <i class="icon-chat"></i>
                  </div>
                  <div class="feature-content">
                      <h5>Counselling</h5>
                      <p>Majoring in Mental Wellness such as: </p>
                      <p>Depression</p>
                      <p>Anxiety</p>
                      <p>Trauma Counselling</p>
                      <p>Grief and Loss Counselling</p>
                  </div>
              </div>
          </div>
          <!-- / -->
          <!-- feaure box -->
          <div class="col-sm-6 col-lg-4 m-15px-tb">
              <div class="feature-box-1 box-shadow-lg">
                  <div class="icon">
                      <i class="icon-target"></i>
                  </div>
                  <div class="feature-content">
                      <h5>Individual Counselling</h5>
                      <p>Encourage client's self-discovery and growth</p>
                      <p>Counselling for immediate or near future concerns</p>
                      <p>Identify, clarify and create a vision for client needs</p>
                      <p>Career Counselling and planning</p>
                      <p>Foster client accountability to increase productivity</p>
                  </div>
              </div>
          </div>
          <!-- / -->
          <!-- feaure box -->
          <div class="col-sm-6 col-lg-4 m-15px-tb">
              <div class="feature-box-1 box-shadow-lg">
                  <div class="icon">
                      <i class="icon-chat"></i>
                  </div>
                  <div class="feature-content">
                    <h5>Relationship</h5>
                    <p>Relationship Counselling </p>
                    <p>Marriage Counselling</p>
                  </div>
              </div>
          </div>
          <!-- / -->
          <!-- feaure box -->
          <div class="col-sm-6 col-lg-4 m-15px-tb">
              <div class="feature-box-1 box-shadow-lg">
                  <div class="icon">
                      <i class="icon-tools-2"></i>
                  </div>
                  <div class="feature-content">
                      <h5>Life Skills Coaching</h5>
                      <p>Interpersonal Communication </p>
                      <p>Social Skiils, Self-esteem, Self-reliance </p>
                      <p>Overcoming obstacles</p>
                      <p>Personality disorders </p>
                      <p>Adjustment difficulties </p>
                      <p>Adult attachment issues</p>
                      <p>Compulsive behaviour</p>
                      <p>Emotional management </p>
                     </div>
              </div>
          </div>
          <!-- / -->
          <!-- feaure box -->
          <div class="col-sm-6 col-lg-4 m-15px-tb">
              <div class="feature-box-1 box-shadow-lg">
                  <div class="icon">
                      <i class="icon-chat"></i>
                  </div>
                  <div class="feature-content">
                      <h5>Stess Management</h5>
                      <p>Dealing with Procrastination</p>
                      <p>Time Management</p>
                      <p>Thinking and memory skills</p>
                      <!-- <p>I design and develop services for customers of all sizes, specializing in creating stylish, modern websites.</p> -->
                  </div>
              </div>
          </div>
          <!-- / -->
          <!-- feaure box -->
          <div class="col-sm-6 col-lg-4 m-15px-tb">
              <div class="feature-box-1 box-shadow-lg">
                  <div class="icon">
                      <i class="icon-tools-2"></i>
                  </div>
                  <div class="feature-content">
                      <h5>CEC Facilitator</h5>
                      <p>Character Education Curriculum Programme</p>
                      <p>Life Skills and EQ Skills </p>
                      <p>Building self-esteem</p>
                      <p>Building self-reliance</p>
                      <p>Social Skills </p>
                      <!-- <p>I design and develop services for customers of all sizes, specializing in creating stylish, modern websites.</p> -->
                  </div>
              </div>
          </div>
          <!-- / -->



      </div>
  </div>
</section>
