       <!-- about us -->
       <section id="about" class="section gray-bg">
        <div class="container">
            <div class="row sm-m-25px-b m-35px-b">
                <div class="col-md-12">
                    <div class="section-title">
                        <h3 class="dark-color text-uppercase">ABOUT ME</h3>
                        <p class="text-uppercase small">Certified professional Counsellor and Mental Health Coach</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center">
                <div class="col-lg-5 m-15px-tb">
                    <div class="about-me-img box-shadow">
                        <img src="assets/img/about.jpeg" title="" alt="">
                        <div class="nav social-icon">
                            <a href="#"><i class="fab fa-facebook-f"></i></a>
                            <a href="#"><i class="fab fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7 m-15px-tb">
                    <div class="about-me">
                        <h4>I am Kokeletso</h4>
                         <p>
                            Kokeletso has experience addressing a variety of issues including anxiety, depression, stress management, trauma, life transitions, difficult relationships, health issues such as Cancer Awareness, grief and loss, and parent child relationship issues. Kokeletso understands that specialized training is important and your ability to feel comfortable and connected to your counsellor or coach is important.
                        </p>
                         <p>
                             Kokeletso is passionate about being a listener first, maintaining open dialogue and helping you explore your own personal goals. she believes a healthy mind is a happy mind and in efforts to ensure that our minds are healthy, we must learn how to cope through difficult times to find that happiness. Finding happiness does not just mean smiling and being able to get through the day without having a complaint but true happiness comes from within.
                         </p>
                         <p>
                            I offer a warm non-judgmental approach that encompasses empathy and compassion and take a person- centered approach while also utilizing cognitive behavioural therapy, play therapy and other evidence-based techniques. My practice specializes in helping individuals, children and adolescents, couples, and families.
                         </p>
                         <p>
                            Kokeletso is a qualified Civil Engineer with almost 9 years of experience in the field of Engineering and a director of a construction company, but with her personal life experience and passion she has for working with people, she then pursued a new career path in Counselling and Life Coaching.
                         </p>
                         <p>
                            Kokeletso is a Certified Life Coach from The School of Life Institution, she holds Advanced Certificate in Counselling and Communication Skills from The South African College of Applied Psychology and Certificate in Mental Health Coaching from University College of London (UCL) and Certificate in Trauma Counselling, she has done the following Courses: Understanding Depression and Low Mood in Young People, Childhood Adversity: The Impact of Maltreatment on Mental Health,  Youth Mental Health, Support for Children who suffered Complex Trauma, Social and Emotional Well being for Adolescents, The Pathway to Transformation after Trauma.
                         </p>
                         <p>
                            I am confident that no problem is too great to overcome. Through Coaching and Counselling, there is always a way to address the issues you face and learn coping strategies to help you both right now and in the future. You might be initially hesitant to seek out help and support for personal matters, but when you do, you will feel confident that you are taking a courageous first step towards getting to a better place and being a better you.
                         </p>
                         <p>
                            Kokeletso she is also a founder of Kokeletso Moiloa foundation, a non-profit organization that provides various services, the organization was established in 2016 aiming to be one of the leading personal growth platform with a mission to educate people, especially in the rural villages about Health, empower women and girls also to teach them transformational ideas.
                         </p>

                        <div class="btn-bar">
                            <a class="m-btn m-btn-theme" href="http://www.kokeletsom-foundation.co.za" target="_blank">Kokeletso Moiloa Foundation</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
