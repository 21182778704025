<footer class="footer">
  <div class="container">
      <div class="row align-items-center">
          <div class="col-md-6 m-10px-tb">
              <div class="nav justify-content-center justify-content-md-start">
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                  <!-- <a href="#"><i class="fab fa-twitter"></i></a> -->
                  <a href="#"><i class="fab fa-instagram"></i></a>
                  <!-- <a href="#"><i class="fab fa-linkedin-in"></i></a> -->
                  <!-- <a href="#"><i class="fab fa-pinterest"></i></a> -->
              </div>
          </div>
          <div class="col-md-6 m-10px-tb text-center text-md-right">
              <p>© 2020 copyright all right reserved</p>
          </div>
      </div>
  </div>
</footer>
