   <!-- Home Banner -->
   <section id="home" class="home-banner-01">
    <div class="container">
        <div class="row full-screen align-items-center p-100px-tb">
            <div class="col-md-6">
                <div class="ht-text">
                    <!-- <h6>Hello there...</h6> -->
                    <h1>Kokeletso Moiloa</h1>
                    <h2>I Am A Passionate <span id="type-it"></span></h2>
                    <p>Just becuase we are able to tolerate Mental and Emotional pain doesn't mean we should.
                      We are allowed to put it down and let go of the heaviness.
                      We are allowed to heal and grow.
                      We are allowed to live a life not dictated by the pain that we carry.
                    </p>
                    <div class="btn-bar go-to">
                        <a class="m-btn m-btn-theme" fragment="services" routerLink="/services">My Services</a>
                        <a class="m-btn m-btn-t-theme" fragment="bookings" routerLink="/bookings">Book An Appointment</a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <img src="assets/img/founder.jpeg" title="" alt="">
            </div>
        </div>
    </div>
    <div class="go-to go-to-next">
        <a routerLink="/about" fragment="about" >
            <span></span>
        </a>
    </div>
</section>
